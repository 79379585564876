import { Address } from './address';
import { Social } from './social';
import { PartnerContact } from './partner-contact';
import { GroupNote } from './group-note';

export interface Partner {
  id: string;
  instanceId: string;
  name: string;
  website?: string;
  partnerPreference?: string;
  img?: string;
  social?: Social;
  address?: Address;
  notes?: GroupNote[];
  allNotes?: GroupNote[];
  audienceType?: Array<string>;
  regions?: Array<string>;
  tags?: Array<string>;
  partnerContacts?: PartnerContact[];
  completeRedirectURL?: string;
  completeWebhookDetails?: any;
  terminateRedirectURL?: string;
  terminateWebhookDetails?: any;
  overQuotaRedirectURL?: string;
  overQuotaWebhookDetails?: any;
  qcRedirectURL?: string;
  qcWebhookDetails?: any;
  connectionId?: string;
  userDomain?: string;
  linkSecurity?: string;
  linkHashingType?: string;
  hashParameters?: string;
  linkHashingKey?: string;
  globalPartner?: string;
  isGlobal?: boolean;
  isPrimaryGlobalVendor?: boolean;
}

