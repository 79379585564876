import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CleanIdExcel } from '../models/cleanIdExcel';

@Injectable({
  providedIn: 'root'
})
export class ClosureService {

  baseUrl = environment.apiEndpoint + '/closure';

  constructor(private http: HttpClient) { }

  GetClosureDetails(id) {
    return this.http.get<any>(this.baseUrl + '/' + id);
  }

  ResetClosure(id) {
    return this.http.get<any>(this.baseUrl + '/reset/' + id)
  }

  clientFileChecks(s3File: string, id: any, projectId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/clientfilecheck/' + id + '/' + projectId + '/' + s3File, {});
  }
  clientFileConfirm(id: any, pid: any, summary: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/clientfileconfirm/' + id + '/' + pid, summary);
  }
  closeStep(step: number, id: any, projectid: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/closestep/' + id + '/' + step + '/' + projectid + '/' + false, {});
  }
  closeStep5PmConfirmCogs(step: number, id: any, projectid: any, pmConfirmCogs: any): Observable<any> {
    if (pmConfirmCogs === undefined || pmConfirmCogs === null) pmConfirmCogs = false;
    return this.http.post<any>(this.baseUrl + '/closestep/' + id + '/' + step + '/' + projectid + '/' + pmConfirmCogs, {});
  }
  closeZeroCompletes(closureDetailsId: any, projectid: any, clientid?: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/closezerocompletes/' + closureDetailsId + '/' + projectid + '/' + (clientid ?? ''), {});
  }
  displayRecData(projectList: Array<string>): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/reconciledata', projectList);
  }
  saveClientCompleteData(details: any, projectId: any, closureId: any, confirm: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/savecompletes/' + projectId + '/' + closureId + '/' + confirm, details);
  }
  saveClientFinanceData(client: any, projectId: any, confirm: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/saveclientfinance/' + projectId + '/' + confirm, client);
  }
  saveVendorFinanceData(vendor: any, projectId: any, confirm: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/savevendorfinance/' + projectId + '/' + confirm, vendor);
  }
  saveVendorSatisfaction(id: any, data): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/' + id + '/vendorsatisfaction', data);
  }
  saveInvoiceData(data: any, id: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/' + id + '/invoice', data);
  }
  saveBillingDate(date: any, id: any): Observable<any> {
    let field: any = {fieldId: 'billingDate'};
    field.dVal = date;
    return this.http.post<any>(this.baseUrl + '/' + id + '/billingdate', field);
  }
  sendVendorExternalLink(closureVendors: any[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/sendvendorexternallink', closureVendors);
  }
  confirmVendorStatus(vendor: any, projectId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/confirmVendorStatus/' + projectId, vendor);
  }
  updateVendorStatus(vendor: any, projectId: any, reason: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/updateVendorStatus/' + projectId + "/" + reason, vendor);
  }
  UpdateMarginReview(id: string, projectId: string, clientTotal: number, vendorTotal: number, vendors: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/updatemarginreview/' + id + '/' + projectId, { fieldId: '', fVal: clientTotal, sVal: vendorTotal.toString(), lVal: vendors });
  }
  sendResetEmail(vendor: any, projectId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/sendResetEmail/' + projectId, vendor);
  }

}

