import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-website',
  template: `<a *ngIf="url" [href]="url" class="or-primary-link"><i class="fas fa-link mr-1 or-secondary"></i>{{ url }}</a>`,
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  @Input() url: string;

  constructor() { }

  ngOnInit() {
  }
}
