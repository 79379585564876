<div class="tagsinput" *ngIf="!toggleOpen">
  <div *ngIf="currentValues.length == 0"> 
    <button type="button" class="picker-button btn btn-outline-info d-flex" (click)="togglePicker()"> 
      <div class="flex-fill">{{ placeholder }}</div> 
      <div><i class="fas fa-chevron-down"></i></div>
    </button>
  </div>
  <div *ngIf="exclude" class="text-danger m-2"><i class="fa-lg fas fa-not-equal"></i></div>
  <span class="tag" *ngFor="let item of currentValues">
    <span class="tag-text or-primary">{{ item.name}}</span><button class="tag-remove" (click)="removeValue(item)"></button>
  </span>
  <div *ngIf="currentValues.length > 0"> 
    <button type="button" class="btn text-primary" (click)="togglePicker()"><i class="far fa-edit"></i></button>
  </div>
</div>

<div *ngIf="toggleOpen" class="border option-dropdown">
  <div class="option-list-row option-dropdown-header p-2">
    <div><input [(ngModel)]="filterText" class="form-control text-filter" placeholder="Search"></div>
    <div *ngIf="allowExclude">
      <select class="form-group clear-input" [(ngModel)]="_exclude">
        <option [ngValue]="false">Include</option>
        <option [ngValue]="true">Exclude</option>
      </select>
      <div></div>
    </div>
  </div>
  <div class="option-dropdown-list scrollbar overflow-y">
    <ng-container *ngFor="let option of options; let i=index ">
      <div class="option-list-row p-1" *ngIf="matchFilter(option.name)">
        <div>      
          <div class="or-checkbox d-block">
            <input type="checkbox" [id]="'option'+i" [(ngModel)]="selectedValues[option.value]"  >
            <label [for]="'option'+i">
              <span class="text-secondary">
                {{ option.name }}
              </span>
            </label>
          </div>
        </div>
        <div> {{ option.count }}</div>
      </div>    
    </ng-container>
  </div>
  <div class="d-flex justify-content-center m-1">
    <button type="button" class="btn btn-outline-info" (click)="cancelChanges()"><i class="far fa-window-close"></i> Cancel</button>
    <button type="button" class="btn btn-outline-success" (click)="saveChanges()"><i class="far fa-save"></i> Save</button>
  </div>

</div>