<div class="dedupeStatus">
  <div> </div>
  <div class="m-auto"><small>Start</small></div>
  <div class="m-auto"><small>Comp.</small></div>
  <div class="m-auto"><small>Term</small></div>
  <div class="m-auto"><small>OQ</small></div>
  <div class="m-auto"><small>QC</small></div>
  <div></div>

  <ng-container *ngFor="let dedupe of _depudeList; let i = index">
    <div *ngIf="i == 0">
      <span data-html="true" tooltip="Select the status to dupe on and also search other projects.">
        <i class="fas fa-info-circle opacity-20"></i>
      </span>
      Current Project
    </div>
    <div class="mb-1" *ngIf="i > 0">
      <or-type-ahead  [selectedValues]=" dedupe.otherId == null ? []: [{value: dedupe.otherId, name:dedupe.otherName }]" [labelField]="'name'" [valueField]="'value'" [dataCallback]="getProjectList" placeholder="Type to search..." (valueChange)="updateDedupeOtherId($event, dedupe); addOtherDedupeRow()" ></or-type-ahead>
    </div>
    <div class="m-auto">
      <div class="or-checkbox">
        <input type="checkbox" [name]="'dupeStart'+i" [id]="'dupeStart'+i" [(ngModel)]="dedupe.start" (change)="handleChange()">
        <label [for]="'dupeStart'+i"></label>
      </div>
    </div>

    <div class="m-auto">
      <div class="or-checkbox">
        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeComplete'+i"  [(ngModel)]="dedupe.complete" (change)="handleChange()">
        <label [for]="'dupeComplete'+i"></label>
      </div>
    </div>

    <div class="m-auto">
      <div class="or-checkbox">
        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeTerm'+i" [(ngModel)]="dedupe.terminate"  (change)="handleChange()">
        <label [for]="'dupeTerm'+i"></label>
      </div>
    </div>

    <div class="m-auto">
      <div class="or-checkbox">
        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeOQ'+i" [(ngModel)]="dedupe.oq" (change)="handleChange()" >
        <label [for]="'dupeOQ'+i"></label>
      </div>
    </div>

    <div class="m-auto">
      <div class="or-checkbox">
        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeQC'+i" [(ngModel)]="dedupe.qc" (change)="handleChange()">
        <label [for]="'dupeQC'+i"></label>
      </div>
    </div>
    <div class="m-auto">
      <i *ngIf="i > 0 && i !== _depudeList.length-1" class="far fa-window-close fa-sm cursor-pointer mr-2 opacity-70" (click)="removeOtherDedupeRow(i)"></i>
    </div>
    <div  *ngIf="i == 0" class="seperator"><hr></div>
  </ng-container>
</div>
