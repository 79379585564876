
export interface Instance {
    id: string;
    name: string;
    description: string;
    img: string;
    logo: string;
    clientSecret?: string;
    responseIDPrivateKey?: string;
    cleanIDPrivateKey?: string;
    cleanIDApiKey?: string;
    vidRealtimePackage?: number;
    vidBatchPackage?: number;
    cleanIDEnable: boolean;
    cleanIDLicenseTrial: boolean;
    projectEnable: boolean;
    clientEnable: boolean;
    clientView: boolean;
    partnerEnable: boolean;
    responseIDEnable: boolean;
    responseIDTransactionAllowance?: number;
    responseIDExpiryDate?: Date;
    cleanIDTransactionAllowance?: number;
    cleanIDExpiryDate?: Date;
    cleanIDImplementation?: string;
    active?: string
}

