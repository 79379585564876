<span tooltip="Filter" (click)="toggleFilter($event)">
  <i class="fas fa-filter fa-xs cursor-pointer or-secondary"></i>
</span>

<div *ngIf="isOpen" class="hidden-filters p-3" [class.show-filters]="isOpen">
  <input [(ngModel)]="filterText" type="search" class="form-control" placeholder="Type to filter...">
  <hr>
  <div class="scrollbar" style="max-height: 150px; overflow-x: auto;">
    <div class="checkbox px-3" *ngFor="let value of values | filter : filterText : ['label'] ">
      <input type="checkbox" [name]="'valCheckbox_' + value.label" [id]="'valCheckbox_' + value.label" [(ngModel)]="value.selected">
      <label class="font-weight-normal" [for]="'valCheckbox_' + value.label">
        {{ value.label }}
        <span class="badge badge-pill badge-light font-weight-normal">
          {{ value.count }}
        </span>
      </label>
    </div>
  </div>
  <hr>
  <div class="btn-group btn-group-sm float-right" role="group">
    <button class="btn btn-secondary mr-1" (click)="isOpen = false">Cancel</button>
    <button class="btn btn-outline-secondary" (click)="applyFilter()"><em class="fas fa-"></em> Apply</button>
  </div>
</div>
