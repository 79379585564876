
export interface SiteSection {
    id: string;
    instanceId: string;
    sortOrder: number;
    type?: string;
    projectTypeId?: string;
    otherInstanceId?: string;
    label: string;
    icon?: string;
    parentId?: string;
    instanceRoleId?: string;
    roles?: [];
}

