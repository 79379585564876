import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-title',
  template: `<div *ngIf="title" class="userTitle font-weight-light text-truncate"> {{ title }}</div>
  <div *ngIf="!title" class="userTitle"> </div>`
})
export class TitleComponent implements OnInit {

  @Input()title: string;

  constructor() { }

  ngOnInit() {

  }

}
