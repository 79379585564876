import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-email-address',
  template: `<a *ngIf="emailaddress != null && emailaddress != ''" [href]="'mailto:' + emailaddress" class="or-primary-link text-lowercase"><i class="fas fa-envelope or-secondary"></i> {{ displayname || emailaddress }}</a>`,
  styleUrls: ['./email-address.component.scss']
})
export class EmailAddressComponent implements OnInit {

  @Input() emailaddress = '';
  @Input() displayname: string;

  constructor() { }

  ngOnInit() {
  }
}
