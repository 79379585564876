import { FeasibilityGroups } from './feasibility';

export interface Project {
  id: string;
  closureDetails:ClosureDetail;
  projectTypeId: string;
  projectCode: string;
  projectName: string;
  description: string;
  createdBy: string;
  projectStatus: string;
  isSuspended: boolean;
  projectStartDate: Date;
  projectEndDate: Date;
  fieldStartDate: Date;
  fieldEndDate: Date;
  totalDays: number;
  totalDaysLeft: number;
  closedDateTime?: any;
  invoicedDateTime?: any;
  category: string;
  countries: string[];
  fullLaunchQuota: number;
  softLaunchQuota: number;
  quotaMode: string;
  isDeDupeParticipantEnabled: boolean;
  isDeDupeDeviceEnabled: boolean;
  isDeDupeOtherProjectEnabled: boolean;
  isDeDupeDeviceAggressive: boolean;
  isFraudDetectionEnabled: boolean;
  isDupeTrackingOnly: boolean;
  fraudDetectionThreshold: number;
  fraudDetectionVersion: number;
  showLandingPage: boolean;
  fraudDetectionGeoCountry: string;
  allowMobile: boolean;
  isAllowedIdCheckEnabled: boolean;
  projectDOR: number;
  alertNavOQ: number;
  surveySettingsId: string;
  bidNumber: string;
  bidSettingsId: string;
  audience: string;
  projectAlertId: string;
  alertDeadlineRemainingDays: number;
  alertDropoutRate: number;
  alertQCRate: number;
  alertPaceMetric: number;
  actualNavOQ: number;
  actualDropoutRate: number;
  actualQCRate: number;
  actualIR: number;
  health: number;
  health_icons: any;
  clientId: string;
  clientName: string;
  clientWebsite: string;
  clientImage: string;
  clientPreference: string;
  clientContactId: string;
  clientContactName: string;
  clientContactEmail: string;
  clientContactPhone: string;
  clientPo: string;
  cloneProjectid: string;
  pm: string;
  pmEmail: string;
  secondPm: Array<string>;
  completes: number;
  ir: number;
  medianLoiDisplay: string;
  operationmode: string;
  bidCompletes: number;
  bidGroups: FeasibilityGroups[];
  metrics: ProjectMetric;
  paceMetric: number;
  paceGoal: number;
  validatedThirdPartyProjectsCreated: boolean;
  projectSegments: ProjectSegment[];
  projectTags: ProjectTag[];
  projectTeam: ProjectTeam[];
  projectDedupeStatus: ProjectDedupeStatus[];
  projectStages:[];
  internalAccountOwnerId: string;
  cleanIdReportEmailedOnce: boolean;
  cleanIdReportEmailedToClient: boolean;
  type: string;
  programmingComplexity: string;
  projectRevenue : number;
  stageDueDate: Date;
  stageDaysLeft: number;
  stageTotalTaskCount: 2;
  stageCompleteTaskCount: 0;
  currentStage: string;
  currentStageColor: string;
  surveySecurityKey?: string;
  surveySecurityKeyName?: string;
}
export interface ClosureDetail {
  id: string;
  projectList: string;
}
export interface ProjectSegment {
  id: string;
  projectId: string;
  segmentName: string;
  segmentStatus: string;
  testSurveyLink: string;
  surveyLink: string;
  sellCPI: number;
  projectIR: number;
  projectLOI: number;
  fullSegmentQuota: number;
  softSegmentQuota: number;
  projectSurveyPartners: ProjectSurveyPartner[];
  surveyPlatForm:string
}
export interface ProjectSurveyPartner {
  id: string;
  isDeleted: boolean;
  segmentId: string;
  partnerId: string;
  partnerStatus: string;
  partnerStartDate: Date;
  partnerFullQuota: number;
  partnerSoftQuota: number;
  buyCPI: number;
  surveyEntryUrl: string;
  completeRedirectURL: string;
  terminateRedirectURL: string;
  overQuotaRedirectURL: string;
  qcRedirectURL: string;
  partnerName: string;
  partnerImage: string;
  partnerContactId: string;
  partnerContactName: string;
  partnerContactEmail: string;
  partnerContactPhone: string;
  partnerProjectNumber: string;
  completedSurveyCount: number;
  validatedSample?: boolean;
}
export interface ProjectTag {
  id: string;
  tag: string;
}
export interface ProjectTeam {
  id: string;
  userId: string;
  displayName: string;
  projectRole: string;
  partnerId: string;
}
export interface ProjectDedupeStatus {
  id: string;
  projectId: string;
  tag: string;
  otherId: string;
  otherName: string;
  start: boolean;
  complete: boolean;
  oq: boolean;
  qc: boolean;
  terminate: boolean;
}
export interface ProjectMetric {
  complete: number;
  terminate: number;
  overQuota: number;
  qC: number;
  clientTerminate: number;
  clientOverQuota: number;
  clientQC: number;
  dropOff: number;
  starts: number;
  dupes: number;
  medianLoi: number;
}
export interface ProjectFavorite {
  projectID: string;
  userID: string;
}
export interface ProjectFilters {
  status: string[];
  clientID: string[];
  partnerID: string[];
  isMine: boolean;
  archivedOnly: boolean;
  freeText: string;
  type: string;
}


export interface LocationData {
  id: string;
  country?: string;
  state?: string;
  county?: string;
  city?: string;
  zip?: string;
}
export interface ClientCompletes {
  pid?: string;
  segment?: string;
}
export interface NavCompletes {
  pid?: string;
  segment?: string;
  status?: string;
}
export interface ProjectDecision {
  id: string;
  externalId: string;
  comment: string;
  decision: string;
  attachment: string;
  email: string;
  emailTo: string;
  fileName: string;
  lastUpdated?: Date;
}

export interface ProjectResponseIdSettings {
  languageId: string,
  questions: {
    questionIdentifier: string,
    questionTypeId: string,
    separator: string,
    classification: string
  }[]
}
