<div class="navbar navbar-light bg-light fixed-top px-5 shadow">
  <div class="row d-flex mr-3 ml-5">
    <div class="d-flex my-1">
      <img class="img-logo" alt="OpinionRoute NavigatOR" src="../../../assets/logo.png">
    </div>
    <div class="h5 w-100 or-title pl-1 font-weight-normal">{{ vendorPricing.projectCode }}</div>
  </div>
  <div class="ml-auto"></div>
  <div class="row px-3 ml-auto mr-4">
    <div class="col">
      <span class="h4 d-flex justify-content-end mb-2">
        {{vendorPricing.partnerName}}
      </span>
      <div class="d-flex align-items-center">
        <div class="input-group d-flex align-items-center">
          PO:&nbsp;<input type="text" class="form-control form-control-sm" [(ngModel)]="vendorPricing.po"/>
          <div class="input-group-append">
            <button class="btn btn-sm bg-secondary opacity-70 text-white" type="button" (click)="updatePO()">
              <i class="fas fa-save fa-lg cursor-pointer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <or-profile-image [url]="vendorPricing?.partnerLogo" [name]="vendorPricing.partnerName" type="square"></or-profile-image>
      <div class="ml-3"></div>
    </div>
  </div>
</div>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="noClosureData">
    <div class="container-fluid">
      <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert" *ngIf="!vendorPricing.emailSent">
        <h3>This project could not find any closing data!</h3>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isClosed">
    <div class="container-fluid">
      <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert" *ngIf="!vendorPricing.emailSent">
        <h3>This project is not closed so pricing cannot be confirmed at this time!</h3>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!noClosureData && isClosed">
    <div class="container">
      <div class="alert alert-warning d-inline-block py-2" role="alert" *ngIf="!isDisabled && vendorPricing.emailSent">
        <ng-container *ngIf="vendorPricing.deadlineMessage != null && vendorPricing.deadlineMessage != ''">
          Please review your final IDs attached and confirm costs below by <strong>{{vendorPricing.deadlineMessage}}</strong><br />
        </ng-container>
        Please reference <strong>{{vendorPricing.projectCode}}</strong> in your invoice to <u>financeteam@opinionroute.com</u>
      </div>
      <div class="card-body scrollbar overflow-y py-0 px-3">
        <div class="row">
          <button type="button" class="btn or-primary-bg text-white btn-sm mb-3 ml-auto" (click)="downloadIDs()">
            <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
            <span class="small">Download Complete IDs</span>
          </button>
          <button type="button" class="btn or-primary-bg text-white btn-sm mb-3 button-spacing" (click)="downloadQCs()">
            <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
            <span class="small">Download QCs</span>
          </button>
        </div>
        <div class="row border">
          <div class="col px-0">
            <table class="table mb-0">
              <thead class="thead-light" *ngIf="totalCompletes > 0">
                <tr class="table-secondary">
                  <td colspan="2">Segment</td>
                  <td>Completes</td>
                  <td>CPI</td>
                  <td>Line Total</td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="totalCompletes > 0">
                  <tr class="table-light" *ngFor="let pricing of vendorPricing?.pricing">
                    <td colspan="2">{{ pricing.segment }}</td>
                    <td class="text-center">{{ pricing.completes }}</td>
                    <td class="text-center">{{ pricing.buyCpi }}</td>
                    <td class="text-center">{{ pricing.total }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="vendorPricing.expenses?.length > 0">
                  <tr class="table-secondary h6" *ngIf="totalCompletes > 0">
                    <td colspan="4" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                    <td class="font-weight-normal py-2">{{ totalFee | currency}}</td>
                  </tr>
                  <tr *ngIf="totalCompletes > 0"><td></td></tr>
                  <tr class="table-secondary">
                    <td>Description</td>
                    <td>Product</td>
                    <td>Qty</td>
                    <td>Unit Price</td>
                    <td>Line Total</td>
                  </tr>
                  <ng-container *ngFor="let expense of vendorPricing.expenses let idx=index">
                    <tr *ngIf="expense.isDeleted != true">
                      <td>{{ expense.description }}</td>
                      <td>{{ expense.product }}</td>
                      <td class="text-center">{{ expense.completes }}</td>
                      <td class="text-center">{{ expense.expenseCpi }}</td>
                      <td class="text-center">{{ expense.amount }}</td>
                    </tr>
                  </ng-container>
                  <tr class="table-secondary h6" *ngIf="totalCompletes > 0">
                    <td colspan="4" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                    <td class="font-weight-normal py-2">{{ totalExpenses | currency }}</td>
                  </tr>
                </ng-container>
                <tr class="text-white h5 border text-center table-primary">
                  <td class="text-left pl-5" colspan="4">Total</td>
                  <td>{{ totalFee + totalExpenses | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--<div class="alert alert-warning d-inline-block mt-5 py-2" role="alert" *ngIf="!vendorPricing.emailSent">
        These details are not currently finalized for approval.
      </div>
    
     <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert" *ngIf="!isDisabled && vendorPricing.emailSent">
        Please <strong>accept</strong>, <strong>dispute</strong> or <strong>forward</strong> the final costs and IDs provided.
      </div>
    -->

      <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert" *ngIf="!isDisabled ">
        Please <strong>accept</strong>, <strong>dispute</strong> or <strong>forward</strong> the final costs and IDs provided.
      </div>

      <!-- nav tabs -->
      <ul class="nav nav-tabs mt-4" *ngIf="!isDisabled">
        <li class="nav-item">
          <a class="nav-link or-primary cursor-pointer" [class.active]="tab == 'Accept'" (click)="changeTab('Accept')">Accept </a>
        </li>
        <li class="nav-item">
          <a class="nav-link or-primary cursor-pointer" [class.active]="tab == 'Dispute'" (click)="changeTab('Dispute')">Dispute </a>
        </li>
        <li class="nav-item">
          <a class="nav-link or-primary cursor-pointer" [class.active]="tab == 'Pending'" (click)="changeTab('Pending')">Not The Right Person </a>
        </li>
      </ul>

      <div class="tab-content bg-light" *ngIf="!isDisabled">

        <!-- Accept -->
        <div class="tab-pane active border border-top-0 p-4" *ngIf="tab == 'Accept'">
          <div class="row mb-3">
            <div class="col-md-4 col-sm-12">
              <label for="email" class="required">Your Email:</label>
              <input type="text" class="form-control" [(ngModel)]="newDecision.email" name="email" [attr.disabled]="isDisabled ? 'disabled' : null" [ngClass]="{'is-invalid': submitted && emailInput.invalid}" />
            </div>
          </div>
          <div class="quickview-wrapper">
            <div class="row">
              <div class="col ckeditor">
                <or-wysiwyg (messageEvent)="receiveMessage($event)" [data]="newDecision.comment" [isDisabled]="isDisabled"></or-wysiwyg>
              </div>
            </div>
            <div class="row mt-2 px-2">
              <div class="col">
                <or-upload smallMsg="Csv and Excel files only" *ngIf="!isDisabled" mode="button" acceptTypes=".xlsx, .xls, .csv, .pdf" [instanceId]="instanceId" includeFileName=true S3Folder="external/{{externalId}}" directToS3=true [(ngModel)]="newDecision.attachment"></or-upload>
              </div>
              <div class="col pr-2 text-right">
                <button type="button" (click)="addDecision('Accepted')" *ngIf="!isDisabled" class="btn btn-sm btn-success opacity-90">Submit</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Dispute -->
        <div class="tab-pane active border border-top-0 p-4" *ngIf="tab == 'Dispute'">
          <div class="row mb-3">
            <div class="col-md-4 col-sm-12">
              <label for="email" class="required">Your Email:</label>
              <input type="text" class="form-control" [(ngModel)]="newDecision.email" name="email" [attr.disabled]="isDisabled ? 'disabled' : null" [ngClass]="{'is-invalid': submitted && emailInput.invalid}" />
            </div>
          </div>
          <div class="quickview-wrapper">
            <div class="align-items-center mb-1">
              <small class="text-danger">(*) Comment is mandatory</small>
            </div>
            <div class="row">
              <div class="col ckeditor">
                <or-wysiwyg (messageEvent)="receiveMessage($event)" [data]="newDecision.comment" [isDisabled]="isDisabled"></or-wysiwyg>
              </div>
            </div>
            <div class="row mt-2 px-2">
              <div class="col">
                <or-upload smallMsg="Csv and Excel files only"   [showFileName]="newDecision?.attachment !== ''" *ngIf="!isDisabled" mode="button" acceptTypes=".xlsx, .xls, .csv, .pdf" [instanceId]="instanceId" includeFileName=true S3Folder="external/{{externalId}}" directToS3=true [(ngModel)]="newDecision.attachment"></or-upload>
              </div>
              <div class="col pr-2 text-right">
                <button type="button" (click)="addDecision('Disputed')" *ngIf="!isDisabled" class="btn btn-sm btn-danger opacity-90">Submit</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Pending (Not Right Person) -->
        <div class="tab-pane active border border-top-0 p-4" *ngIf="tab == 'Pending'">
          <div class="row mb-3">
            <div class="col-4">
              <label for="email" class="required">Your Email:</label>
              <input type="text" class="form-control" [(ngModel)]="newDecision.email" name="email" [attr.disabled]="isDisabled ? 'disabled' : null" [ngClass]="{'is-invalid': submitted && emailInput.invalid}" />
            </div>
            <div class="col-4">
              <label for="email" class="required">To</label>
              <input type="text" class="form-control" [(ngModel)]="newDecision.emailTo" name="emailTo" [attr.disabled]="isDisabled ? 'disabled' : null" />
            </div>
            <div class="col d-flex align-items-end px-0 mb-1">
              <button type="button" (click)="addDecision('Pending')" *ngIf="!isDisabled" class="btn btn-sm btn-info opacity-90">Send</button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mt-5 mb-1" *ngIf="decisions?.length > 0" >
        <div class="h5 opacity-70"><i class="fas fa-comments"></i> Comments</div>
      </div>
      <div class="scrollbar overflow-y overflow-x-hidden">
        <ul class="nav">
          <ng-container *ngFor="let decision of decisions let noteIdx = index">
            <li class="my-2 w-100">
              <div class="note mb-1 w-100 border or-secondary"
                  [ngClass]="{
                    'note-success': decision.decision == 'Accepted' || decision.decision == 'Auto-Accepted',
                    'note-confirmed': decision.decision == 'Confirmed',
                    'note-danger': decision.decision == 'Disputed',
                    'note-info': decision.decision == 'Pending',
                    'note-reset': decision.decision == 'Reset'
                  }">
                <div class="mb-2">
                  <small class="mt-1 or-title" *ngIf="decision.email">{{ decision.email }}</small>
                  <span class="note-date float-right mt-1">{{ decision.lastUpdated | date: 'MMM d, y HH:mm' }}</span>
                </div>
                <p *ngIf="decision.comment == '' && decision.decision == 'Accepted'"><strong>Accepted</strong> with no comment!</p>
                <p *ngIf="decision.comment != ''" [innerHTML]="decision.comment"></p>

                <a class="nav-link p-0 small" *ngIf="decision.attachment !== null"  [href]="decision.attachment" target="_blank">
                  <button type="button" class="btn border-0 w-100 shadow-none px-0 text-left">
                    <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
                    <span class="small text-info">{{decision.fileName | lowercase}}</span>
                  </button>
                </a>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="text-muted text-center text-white my-3">
        <small>OpinionRoute ©2024</small>
      </div>
    </div>
  </ng-container>
</ng-container>
