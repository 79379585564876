import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RuleHeader } from 'core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  baseUrl = environment.apiEndpoint + '/Rules';

  constructor(private http: HttpClient) {}

  GetRules(projectId: string): Observable<RuleHeader[]> {
    return this.http.get<RuleHeader[]>(this.baseUrl  + '/' + projectId + '/rules');
  }
  GetRulesSummary(bidNumber: string): Observable<RuleHeader[]> {
    return this.http.get<RuleHeader[]>(this.baseUrl  + '/summary/' + encodeURI(bidNumber) + '/rules');
  }

  AddRule(model: any): Observable<RuleHeader> {
    return this.http.post<RuleHeader>(this.baseUrl + '/rule', model);
  }

  EditRule(model: any): Observable<RuleHeader> {
    return this.http.put<RuleHeader>(this.baseUrl + '/rule', model);
  }

  DeleteRule(id: any) {
    return this.http.delete(this.baseUrl + '/rule/' + id);
  }

}
