<small class="cursor-pointer text-nowrap" (click)="toggleDropdown($event)" [class.clickable]="addClickableClass" *ngIf="!text">
  {{ getCurrent() }} <ng-container *ngIf="showBtn === true"><i class="fas fa-chevron-circle-down ml"></i></ng-container>
</small>

<span class="cursor-pointer text-nowrap" (click)="toggleDropdown($event)" [class.clickable]="addClickableClass" *ngIf="text">
  {{ text }} <ng-container *ngIf="showBtn === true"><i class="fas fa-chevron-circle-down ml"></i></ng-container>
</span>

<div class="btn-group d-flex" dropdown #dropdown="bs-dropdown" [class.clickable]="addClickableClass">
  <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
    <div class="d-block text-right px-2" (click)="toggleDropdown($event)">
      <i class="far fa-window-close fa-sm cursor-pointer"></i>
    </div>

    <li role="menuitem" *ngFor="let value of values" class="expandable" >
      <a class="dropdown-item cursor-pointer" (click)="applyValue($event, value)">{{ value.name }}</a>
    </li>
  </ul>
</div>


