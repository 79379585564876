<div (click)="toggleFilter($event, ddFilters)">
  <i class="fak fa-filter fa-xs grey4 cursor-pointer" [class.filter-mode]="isOpen"></i>
</div>

<div dropdown #ddFilters="bs-dropdown" container="body" class="dropdown">
  <div class="dropdown-menu ml-2" *dropdownMenu role="menu"
      [ngClass]="{'dropdown-menu-left': position === 'left', 'dropdown-menu-right': position === 'right'}"
      [style]="position +': -'+ positionDistance + 'px !important'"
      aria-labelledby="button-triggers-manual" (mouseleave)="toggleFilter($event, ddFilters)">
    <div class="col mb-2 grey5 nav-font14 d-flex align-items-center">
      <span class="font-weight-bold nav-font14 grey5">Filter by</span>
      <i class="fas fa-close fa-md grey4 ml-auto" (click)="toggleFilter($event, ddFilters)"></i>
    </div>
    <div *ngIf="type != 'simple'" class="row mt-1 mb-3 px-3">
      <div class="col nav-font12 nav-accent-blue">
        <span class="cursor-pointer" (click)="clearAllFilters(); applyFilter()">Reset Filters</span>
      </div>
    </div>
    <hr class="mx-1 my-0 nav-border-primary">
    <div class="p-2">
      <ng-container *ngIf="values && values.length > 0">
        <ng-container *ngFor="let value of values; let i = index">
          <ng-container *ngIf="i === 0 || values[i].group !== values[i - 1].group">
            <span class="nav-font14 grey4 ml-2 pl-1 my-2 d-block" *ngIf="values[i]?.group">{{ value.group }}</span>
          </ng-container>
          <div class="checkbox px-3">
            <input type="checkbox" [name]="'valCheckbox_' + value.value" [id]="'valCheckbox_' + value.value" [(ngModel)]="value.selected" (change)="applyFilter()">
            <label class="font-weight-normal nav-font14 grey5" [for]="'valCheckbox_' + value.value">
              {{ value.label }}
            </label>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </div>
</div>
