<div (click)="toggleSorting($event, ddSorting)">
  <i class="fak fa-sorting fa-sm grey4 cursor-pointer" [class.sorting-mode]="isOpen"></i>
</div>
<div dropdown #ddSorting="bs-dropdown" container="body">
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right grey4 cursor-pointer px-1" role="menu" (mouseleave)="toggleSorting($event, ddSorting)">
    <li *ngFor="let item of values; let i = index">
      <a class="dropdown-item nav-font14" (click)="applySorting(item.value); toggleSorting($event, ddSorting)">{{item.label}}</a>
      <hr class="mx-2 my-0" *ngIf="i < values?.length - 1">
    </li>
  </ul>
</div>
