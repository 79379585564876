<ng-container *ngIf="auth.isInternal() && !isClientview">
  <div class="btn-group notifications" dropdown (onShown)="openMessages()" container="body">

    <div class="bell d-flex align-items-center justify-content-center position-relative">
      <div class="d-flex align-items-center justify-content-center cursor-pointer shadow-sm" type="button" dropdownToggle aria-controls="dropdown-animated">
        <i class="far fa-bell or-secondary fa-lg"></i>
        <span *ngIf="auth.getUser()?.hasNewMessages" class="badge badge-pill count">&nbsp;</span>
      </div>
    </div>

    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 shadow notifications-content" role="menu" style="width: 400px;">
      <li class="py-2 or-primary-bg text-white">Notifications
        <span class="badge badge-pill bg-white text-uppercase float-right mt-1"></span>
        <a [routerLink]="['/alert-settings']" class="float-right">
          <i class="fas fa-expand cursor-pointer opacity-90 text-white"></i>
        </a>
      </li>

      <div class="scrollbar overflow-y" *ngIf="!loading">
        <li *ngFor="let message of items">
          <div class="media">
            <!-- ProjectStatus -->
            <i *ngIf="['ProjectAllocation','ProjectCreated','ProjectStatusClosed','ProjectStatusInvoiced','ProjectStatusLive','ProjectStatusPaused','ProjectOverQuota'].includes(message.type)" class="align-self-center notification-icon fak fa-project-alerts or-primary"></i>
            <i *ngIf="['VendorCostConfirmed','VendorOverQuota','VendorOverQuotaGroup','VendorProjectStop','VendorReconciledID','VendorStatus'].includes(message.type)" class="align-self-center notification-icon fa-md fa-vendors fak or-primary"></i>
            <i *ngIf="['NoteAdded'].includes(message.type)" class="align-self-center notification-icon fak fa-notes fa-md or-primary"></i>
            <i *ngIf="['SecondaryPmChange'].includes(message.type)" class="align-self-center notification-icon fa-md fa-team fak or-primary"></i>
            <i *ngIf="['DOR Alert','OQ Alert'].includes(message.type)" class="align-self-center notification-icon fak fa-project-alerts or-primary"></i>
            <i *ngIf="['MilestoneEndDate','TasksAssignedToMe','TasksStatusChange'].includes(message.type)" class="align-self-center notification-icon fa-md fa-tasklist fak or-primary"></i>

            <div class="media-body">
              <a [routerLink]="['/projects', message.projectId]" class="font-weight-bold or-primary-link text-white"><h6 class="my-0 or-secondary">{{ message.title }}</h6></a>
              <small class="badge badge-pill badge-success text-white text-uppercase float-right mt-1" *ngIf="message.isNew">new</small>
              <span><i class="far fa-clock fa-sm pr-1"></i>{{ message.time }}</span>
            </div>
          </div>
        </li>
      </div>

      <li *ngIf="loading">
        <div class="row p-2">
          <div class="col d-flex justify-content-center">
            <div class="loading loading-lg"></div>
          </div>
        </div>
      </li>

    </ul>
  </div>

</ng-container>

<ng-container *ngIf="auth.isVendor()">
  <div class="btn-group notifications">
    <span type="button" class="btn-lg p-2" [routerLink]="['/alert-settings']">
      <i class="far fa-bell text-secondary fa-lg"></i>
    </span>
  </div>
</ng-container>
