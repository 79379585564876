<div class="bg-light d-inline-block filters" *ngIf="listsettings.appliedFilters.length > 0">
  <div class="px-0 py-2">
    <div class="card bg-light mx-1 d-inline-block mb-2" *ngFor="let filter of listsettings.appliedFilters">
      <div class="card-header py-1 px-2">
        <small>{{ filter.label }}</small>
        <i class="far fa-window-close fa-sm cursor-pointer" (click)="removeFilter(filter)"></i>
      </div>
      <div class="card-body py-2 px-2">
        <span class="badge badge-pill badge-info mr-1" *ngFor="let value of filter.values">
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</div>
<div class="table-responsive scrollbar">
  <table class="table table-hover table-light table-striped">
    <thead>
      <tr>
        <ng-container *ngFor="let col of layout; let idx = index">
          <th class="nowrap nav-font14 grey5" *ngIf="col.type !='actions-dropdown' && col.type!='actions-inline'">
            <or-list-filter *ngIf="col.filterable" [values]="col.filterValues" [ngClass]="{'position-relative': (idx+1) === layout?.length, 'last-column': (idx+1) === layout?.length}" (opened)="getFilterValues(col)" (filter)="applyFilter($event, col)"></or-list-filter>&nbsp;
            <div class="d-inline-block" [ngClass]="{'sortable': col.sortable}" (click)="col.sortable > 0 && sortList(col)">
              {{ col.label }}
              <i *ngIf="col.sortable" class="fas fa-sm or-secondary"
                 [ngClass]="{'fa-sort-down': sortBy == col.id && sortDir == 'DESC', 'fa-sort-up': sortBy == col.id && sortDir == 'ASC', 'fa-sort': sortBy != col.id}"></i>
            </div>
          </th>
          <th class="align-middle nav-font14 grey5" *ngIf="col.type=='actions-dropdown' || col.type=='actions-inline'">
            {{showActionColLabel == true ? col.label : "" }}
            <!-- TO DO: SUPPORT MORE TABLE LEVEL ACTIONS-->
            <i *ngIf="enableExport" class="fas fa-download cursor-pointer or-secondary" (click)="exportTable()"></i>
          </th>
        </ng-container>
      </tr>
      <tr *ngIf="summary != null" class="table-primary">
        <ng-container *ngFor="let col of layout">
          <th>
            {{ summary[col.id] }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of displayList; index as i">
        <tr class="grey5 nav-font14" [ngClass]="{'table-info': item?.selected}">
          <td *ngFor="let col of layout" [class.selectable]="col.selectable" class="align-middle" (click)="rowSelected(col, item)"
          [ngClass]="'id-list-' + col.label.toLowerCase().replace(' ','')"
          [class.nowrap]="col.type !== null && col.type !== 'text' && col.type !== 'number' && col.type !== 'bool'">

            <ng-container *ngIf="col.editOnly || (item.editMode != null && item.editMode[col.id] != null)">
              <ng-container *ngIf="col.type == null || col.type == 'text'">
                <ng-container *ngIf="col.options == null; then textField; else dropdownField"></ng-container>

                <ng-template #textField>
                  <input type="text" class="form-control" [(ngModel)]="item.editMode[col.id].value" (ngModelChange)="autoSaveChange($event, item, col)">
                </ng-template>

                <ng-template #dropdownField>
                  <select class="custom-select d-block w-100" [ngModel]="item.editMode[col.id].value"
                    (ngModelChange)="autoSaveChange($event, item, col)">
                    <option *ngFor="let option of col.options" [ngValue]="option"> {{ option }}</option>
                  </select>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="col.type == 'number' || col.type == 'percent' || col.type == 'currency' ">
                <input type="text" class="form-control" [(ngModel)]="item.editMode[col.id].value">
              </ng-container>

               <ng-container *ngIf="col.type == 'users'">
               <ng-container *ngIf="isArrayOfObjects(item[col.id])">
                <or-type-ahead [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList"
                  placeholder="Type to search..."
                  (valueChange)="item.editMode[col.id].value = ($event.length == 0) ? null : $event[0].id"
                  [selectedValues]="item[col.id]"></or-type-ahead>
               </ng-container>
              </ng-container>

              <ng-container *ngIf="col.type == 'date' || col.type == 'countdown'">
                <div class="input-group mb-1">
                  <input type="text"  autocomplete="off" class="form-control form-control-sm bg-white" readonly #dp="bsDatepicker"
                    [bsValue]="item.editMode[col.id].value" (bsValueChange)="autoSaveChange($event, item, col)"
                    bsDatepicker [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                  <div class="input-group-append">
                    <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i
                        class="far fa-calendar-alt"></i></button>
                  </div>
                </div>
              </ng-container>

              <div class="mt-1 float-right">
                <button *ngIf="col.editable && !(col.editOnly == true)" (click)="item.editMode[col.id] = null" type="button"
                  class="btn btn-outline-secondary mx-1 py-2">
                  <i class="fa fa-times fa-xs"></i>
                </button>
                <button *ngIf="col.editable && (col.autoSave == null || col.autoSave == false)"
                  (click)="saveChange(item, col)" type="button" class="btn btn-outline-secondary">
                  <i class="fa fa-check fa-xs"></i>
                </button>
              </div>

            </ng-container>

            <ng-container *ngIf="!(col.editOnly == true) && (item.editMode == null || item.editMode[col.id] == null)">
              <span *ngIf="col.editable && col.type != 'status'" tooltip="Edit" (click)="enableEdit(item, col)">
                <i class="fas fa-edit cursor-pointer or-secondary"></i>&nbsp;
              </span>
              <ng-container *ngIf="col.type == 'stage'">
                <or-project-stage [stage]="item['currentStage']" [color]="item['currentStageColor']" [percentComplete]="(item?.stageCompleteTaskCount / item?.stageTotalTaskCount)*100"></or-project-stage>
              </ng-container>

              <ng-container *ngIf="col.type == null || col.type == 'text' || col.type == 'number' || col.type == 'bool'">
                <a *ngIf="col.selectable && clickroute!=''" class="nav-accent-blue font-weight-bold nav-link p-0" [routerLink]="[clickroute, item[rowid]]" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                  {{ item[col.id] }}
                </a>
                <a *ngIf="col.selectable && clickroute=='' && item.fileName != 'Rollback'" class="nav-accent-blue font-weight-bold nav-link p-0" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                  {{ item[col.id] }}
                </a>
                <a *ngIf="col.selectable && clickroute=='' && item.fileName == 'Rollback'" class="nav-accent-blue font-weight-bold p-0 noFile" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                  {{ item[col.id] }}
                </a>
                <div *ngIf="!col.selectable" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                  <ng-container *ngIf="col.type == 'number'">
                    {{ item[col.id] | number }}
                  </ng-container>
                  <ng-container *ngIf="col.type != 'number'">
                    {{ item[col.id] }}
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'percent'">
                <div [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                  {{ item[col.id] | number }} %
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'currency'">
                <div [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                 {{ item[col.id] | currency }}
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'cleanID'">
                {{ cleanIDImplementation(item, col) }}
              </ng-container>
              <ng-container *ngIf="col.type == 'icon'">
                <i class="cursor-default" [ngClass]="item[col.id]"></i>
              </ng-container>
              <ng-container *ngIf="col.type == 'status'">
                <div class="or-dropdown-status id-list-status">
                  <or-dropdown-status *ngIf="col.editable" [status]="item[col.id]" [projectId]="item.id"></or-dropdown-status>
                </div>
                <span *ngIf="!col.editable " [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">{{item[col.id]}}</span>
              </ng-container>
              <ng-container *ngIf="col.type == 'link'">
                <a target="_blank" href="http://{{item[col.id]}}">{{item[col.id]}}</a>
              </ng-container>
              <ng-container *ngIf="col.type == 'email'">
                <or-email-address [emailaddress]="item[col.id]"></or-email-address>
              </ng-container>
              <ng-container *ngIf="col.type == 'img'">
                <or-profile-image [url]="item[col.id]" [name]="item.name" size="small" showInitials="true"></or-profile-image>
              </ng-container>
              <ng-container *ngIf="col.type == 'users'">
                <div class="d-inline-flex ml-2 position-relative users">
                  <ng-container *ngIf="isArrayOfObjects(item[col.id]); else notArrayOfObjects">
                  <span *ngFor="let user of item[col.id]" tooltip="{{ user.name }}" container="body">
                    <or-profile-image [name]="user.name" size="small" showInitials="true"></or-profile-image>
                  </span>
                  </ng-container>
                  <ng-template #notArrayOfObjects>
                    <span tooltip="{{ item[col.id] }}" container="body">
                      <or-profile-image [name]="item[col.id]" size="small" showInitials="true"></or-profile-image>
                    </span>
                  </ng-template>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'address'">
                <or-address [address]="item[col.id]"></or-address>
              </ng-container>
              <ng-container *ngIf="col.type == 'countdown'">
                <span>{{ getCountdownString(item[col.id]) }} </span>
                <div><small>{{ item[col.id] | date: 'MMM d, y' }}</small></div>
              </ng-container>
              <ng-container *ngIf="col.type == 'date'">
                {{ item[col.id] | date: 'MMM d, y' }}
              </ng-container>
              <ng-container *ngIf="col.type == 'datetime'">
                {{ item[col.id] | date: 'MMM d, y HH:mm' }}
              </ng-container>
              <ng-container *ngIf="col.type == 'tag'">
                <span *ngIf="item[col.id].mode == null || item[col.id].mode == 'dot'"
                  [ngClass]="'badge-' + item[col.id].type || 'info'" class="badge badge-pill p-2">
                  {{ item[col.id].label }}
                </span>
                <span *ngIf="item[col.id].mode == 'badge'">
                  <div [ngClass]="'bg-' + item[col.id].type || 'info'" class="rounded-circle dot float-left mt-1"></div>
                  <small>&nbsp;{{ item[col.id].label }}</small>
                </span>
              </ng-container>
              <ng-container *ngIf="col.type == 'tags'">
                <div class="row tags scrollbar overflow-y">
                  <div *ngFor="let tag of item[col.id]" class="badge badge-pill badge-light text-uppercase m-1 or-primary-bg text-white">
                    {{ tag.tag }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'toggle'">
                <or-toggle-button [id]="item.Id" [isChecked]="item[col.id]" [isDisabled]="true" msgON="YES" msgOFF="NO">
                </or-toggle-button>
              </ng-container>

              <ng-container *ngIf="col.type == 'actions-dropdown' && actions && auth.isInternal()">
                <div class="btn-group btn-group-sm id-list-actions" dropdown container="body">
                  <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary" controls="dropdown-basic">
                    <span><i class="fa fa-ellipsis-h"></i></span>
                  </button>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                    <li *ngFor="let action of actions" role="menuitem">
                      <or-action-button [label]="action.label" [description]="action.description" [icon]="action.icon" (selected)="actionSelected(item, action.type)"></or-action-button>
                    </li>
                  </ul>
                </div>
              </ng-container>

              <ng-container *ngIf="col.type == 'alert'">
                <ng-container *ngFor="let alert of item[col.id+'_icons']">
                    <span container="body"  tooltip="{{ alert.message }}" class="mr-1">
                      <i [ngClass]="alert.icon"></i>
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="col.type == 'health'">
                <ng-container *ngFor="let alert of item[col.id+'_icons']">
                  <span html="true" tooltip="{{ alert.tooltip }}" container="body" class="health badge badge-pill {{ alert.status }} mr-1 p-2">
                    {{ alert.message }}
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="col.type == 'actions-inline'  && actions">
                <div class="btn-group btn-group-sm">
                  <button *ngFor="let action of actions" type="button" (click)="actionSelected(item, action.type)"
                    class="btn btn-outline-secondary" tooltip="{{ action.label }}">
                    <i [ngClass]="'fa fa-' + action.icon + ' or-secondary'"></i>
                  </button>
                </div>
              </ng-container>


              <ng-container *ngIf="col.type == 'actions-checkbox'  && actions">
                <div class="btn-group btn-group-sm">
                  <input  *ngFor="let action of actions"  class="btn btn-outline-secondary" tooltip="{{ action.label }}" (change)="actionSelected(item, action.type, $event)" type="checkbox">
                </div>
              </ng-container>

            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" *ngIf="itemsToLoad < itemCount" (click)="loadMore()">Load More</button>

</div>
