import { EventEmitter, Injectable} from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import ReconnectingWebSocket from 'reconnecting-websocket';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  private destination = 'wss://uq2vbh43hj.execute-api.us-east-2.amazonaws.com/prod';
  private ws: any;

  public newMessageReceived = new EventEmitter<boolean>();

  baseUrl = environment.apiEndpoint + '/alertmessage';

  constructor(private http: HttpClient, public auth: AuthService) { }

  public connectWebSocket() {
    if (!this.auth.loggedIn()) return;

    this.ws = new ReconnectingWebSocket(this.destination + `?instanceId=${this.auth.getUser().instanceId}`);

    this.ws.addEventListener('open', () => { console.log('Websocket is now connected!'); });
    this.ws.addEventListener('close', () => {
      console.log('Websocket is closed! Reconnecting...');
      this.ws.reconnect();
    });

    this.ws.addEventListener('error', (e) => {
      console.log(`Websocket failed! ${e}`);
      this.ws.reconnect();
    });

    this.ws.addEventListener('message', (res) => {
      var msg = JSON.parse(res.data);
      console.log(`Websocket message received! ${ JSON.stringify(msg)}`);

      if (this.newMessageReceived == undefined) this.newMessageReceived = new EventEmitter<boolean>();
      this.newMessageReceived.emit(msg.data);
    });
  }

  checkNewMessages(instanceId: string = '', lastView: Date) { return this.http.get<any>(this.baseUrl + '/checknew?lastAlertViewDate=' + lastView); }
  getLatestAlerts() { return this.http.get<any[]>(this.baseUrl); }
  getAlerts(total: number) { return this.http.get<any[]>(this.baseUrl + '/alerts/' + total); }
  getNotificationSettings() { return this.http.get<any[]>(this.baseUrl + '/notification'); }
  deleteNotificationSettings(id: string) { return this.http.delete(this.baseUrl + '/notification/' + id); }
  insertNotificationSettings(setting: any) { return this.http.post(this.baseUrl + '/notification', setting); }
  getNotificationUsers() { return this.http.get<any[]>(this.baseUrl + '/notificationusers'); }
  copyNotificationSettings(userId: any) { return this.http.post(this.baseUrl + '/notificationcopy/' + userId, {}); }
}
