import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertifyService, ExternalService, ProjectDecision, UtilsService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, Validators } from '@angular/forms';
import { ClosureService } from 'projects/core/src/services/closure.service';

@Component({
  selector: 'app-vendor-pricing',
  templateUrl: './vendor-pricing.component.html',
  styleUrls: ['./vendor-pricing.component.scss']
})
export class VendorPricingComponent implements OnInit {
  loading: boolean = true;
  isClosed: boolean = true;
  noClosureData: boolean = true;

  externalId: string;
  instanceId: string;
  vendorPricing:any = {};
  vendorIds:any = {};
  closureComplete = false;
  totalExpenses:any;
  totalFee:any;
  totalCompletes:any;
  isDisabled = false;
  submitted = false;
  decisions: ProjectDecision[] = [];
  newDecision = { id: uuidv4(), comment: '', attachment:'' } as ProjectDecision;
  emailInput: FormControl;
  emailToInput: FormControl;
  emailTo: string;
  tab = 'Accept';

  constructor(
    private route: ActivatedRoute,
    private externalService: ExternalService,
    private closureService: ClosureService,
    private cdr: ChangeDetectorRef,
    private alertify: AlertifyService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');
      if (id) {
        this.externalId = id;
        this.getVendorPricing();
      } else {
        this.alertify.error('This is not a valid id.');
      }
    });
  }

  getVendorPricing() {
    this.externalService.GetVendorPricing(this.externalId).subscribe(data => {
      if (data === null) {
        this.noClosureData = true;
        return;
      }

      this.isClosed = (data.projectStatus === 'Closed' || data.projectStatus === 'Invoiced');
      this.vendorPricing = data;
      this.instanceId = data.instanceId;
      this.noClosureData = false;
    }, error => console.error(error),
    () => {
      if (this.noClosureData) {
        this.loading = false;
        return;
      }

      this.totalExpenses = this.vendorPricing?.expenses?.map(o => o.amount).reduce((prev, curr) => prev + curr, 0 );
      this.totalFee = this.vendorPricing?.pricing?.map(o => o.completes * o.buyCpi).reduce((prev, curr) => prev + curr, 0 );
      this.totalCompletes = this.vendorPricing?.pricing?.map(o => o.completes).reduce((prev, curr) => prev + curr, 0 );
      this.getDecisions();
      this.loading = false;
    });
  }

  receiveMessage($event) { this.newDecision.comment = $event; }

  getDecisions() {
    if (this.externalId == null || this.externalId == '') {
      this.alertify.error('This is not a valid id');
      return;
    }

    this.externalService.GetDecisions(this.externalId).subscribe(result => {
      this.decisions = result.map(item => {
        if (item.attachment !== null && item.attachment !== '') {
          item.fileName = item.attachment.substring(item.attachment.lastIndexOf('/') + 1);
        }
        return item;
      });

      //this.isDisabled = this.decisions.filter(x =>
      //  x.decision === 'Accepted' ||
      //  x.decision === 'Auto-Accepted').length > 0;
      this.isDisabled = this.decisions[0]?.decision === "Accepted" || this.decisions[0]?.decision === "Auto-Accepted";

      this.cdr.detectChanges();
    }, error => console.error(error));
  }

  changeTab(newTab) {
    this.tab = newTab;
    this.getDecisions();
  }

  addDecision(type: string) {
    this.submitted = true;
    this.emailInput = new FormControl(this.newDecision.email, [Validators.required, Validators.email]);

    if (type !== 'Pending') {
      if (!this.emailInput.valid) {
        this.alertify.error('Please type in a valid email');
        return;
      }

      if (type === 'Disputed' && this.newDecision.comment.trim() === '') {
        this.alertify.error('A comment is mandatory for a Dispute');
        return;
      }
    } else {
      this.emailToInput = new FormControl(this.newDecision.emailTo, [Validators.required, Validators.email]);

      if (!this.emailInput.valid || !this.emailToInput.valid) {
        this.alertify.error('Email From and To are mandatory');
        return;
      }

      this.emailTo = this.newDecision.emailTo;
      this.newDecision.comment = `<p>Request forwarded to <span class="font-weight-bold">${this.emailTo}</span></p>`;
    }

    this.newDecision.decision = type;
    this.newDecision.externalId = this.externalId;

    this.decisions.unshift(this.newDecision);

    this.externalService.addDecision(this.newDecision).subscribe(() => {
      this.newDecision = { comment: '',attachment:'' } as ProjectDecision;

      if (type === 'Pending') {
        this.sendEmailRightPerson();
      }
      if (type === 'Disputed') {       
        this.newDecision = { id: uuidv4(), comment: '', attachment:'' } as ProjectDecision;      
      }
    }, error => console.error(error),
    () => {
      this.getDecisions();
      if (type === "Accepted") this.isDisabled = true;
    });
    
  }

  downloadIDs() {
    const filename = 'ProjectCompletes.csv';
    let csvContent = 'Id, Segment \n';

    this.externalService.GetVendorIDs(this.externalId).subscribe(data => {
      this.vendorIds = data;
      this.vendorIds.forEach (c => {
        csvContent += c.panelistId.toString() + ',' + c.segment + '\n';
      });
      this.utils.generateXLSX(csvContent, filename);
    }, error => console.error(error));
  }
  downloadQCs() {
    const filename = 'ProjectQC.csv';
    let csvContent = 'Id, Segment,Reason \n';

    this.externalService.GetVendorQCs(this.externalId).subscribe(data => {
      this.vendorIds = data;
      this.vendorIds.forEach (c => {
        csvContent += c.panelistId.toString() + ',' + c.segment + ',' + c.reason + '\n';
      });
      this.utils.generateXLSX(csvContent, filename);
    }, error => console.error(error));
  }

  sendEmailRightPerson() {
    // Create an array with only 1 vendor to call the function
    if (this.vendorPricing !== null) {
      let vendors: any[] = [{
        id: this.vendorPricing.id,
        partnerId: this.vendorPricing.partnerId,
        partnerContactId: null,
        vendorName: this.vendorPricing.partnerName,
        externalPortalUrl: this.vendorPricing.externalPortalUrl,
        externalId: this.externalId,
        sendEmailTo: this.emailTo,
        action: 'Pending'
      }];

      if (this.externalId === null) {
        this.alertify.warning("Must add Project Number or add line item in Vendor Pricing tab and save changes.");
        return;
      }

      this.closureService.sendVendorExternalLink(vendors).subscribe(data => {
        if (data) {
          this.alertify.success('Email sent succesfully');
        }
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  updatePO() {
    this.externalService.UpdatePO(this.externalId, this.vendorPricing.po).subscribe(data => {
      this.alertify.success('PO updated succesfully');
    }, error => {
      this.alertify.error('PO failed to update');
    });
  }
}
