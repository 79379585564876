import { ReportsService } from './../../../services/reports.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InstanceService } from '../../../../../core/src/services/instance.service';
import { AuthService } from 'projects/core/src/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'or-cleanid-report',
  templateUrl: './cleanid-report.component.html',
  styleUrls: ['./cleanid-report.component.scss']
})
export class CleanidReportComponent implements OnInit {

  iframeUrl = '';
  reportId = "89";
  instanceId = '';
  @ViewChild('myIframe') public myIframe;


  constructor(private instanceService: InstanceService, private reportService: ReportsService, public auth: AuthService, private route: ActivatedRoute) {

    this.route.params.subscribe((params) => {
      this.reportId = (params.mode == 'nav') ? "174" : "89";

      if(params.instanceid != null){
        this.instanceId = params.instanceid;
      }
      this.loadDashboard();
    });
  }
  ngOnInit() {
    
  }
  loadDashboard() {

    var instance = this.auth.getUser().instanceId?.toUpperCase() ?? '';

    if (instance == "6FF4016F-CC81-4683-11E5-08D962FBAE15") {
      //C+R Research need old zoho reports
      setTimeout(() => {
        const instanceId = instance.toLowerCase() === 'aaade042-c139-4285-33fb-08d76ad2902a' ? '339EB389-C219-4E1D-9378-08D7AE29A187' : instance; //if we are on our dev instance we just load reports for live instance
        this.iframeUrl = 'https://analytics.zoho.com/open-view/2230517000012513222/406eace9561cffaa6a31312434abf6c2?ZOHO_CRITERIA=%22transactiondata%22.%22accountid%22%3D%27' + instanceId + '%27';
        const iframe = this.myIframe.nativeElement;
        if (iframe != null) {
          iframe.src = this.iframeUrl;
        }

      }, 500);
    }
    else {
      this.reportService.GenerateMetabaseEmbedToken(this.reportId, this.instanceId).subscribe(data => {
        this.iframeUrl = data;
        const iframe = this.myIframe.nativeElement;
        if (iframe != null) {
          iframe.src = this.iframeUrl;
        }
      }, error => {

      });
    }
  }
}
