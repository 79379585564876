import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-phone',
  template: `<a *ngIf="phone" [href]="'tel:' + phone" class="or-primary-link"><i class="fas fa-phone or-secondary"></i> {{ phone }}</a>`
})
export class PhoneComponent implements OnInit {

  @Input()phone: string;

  constructor() { }

  ngOnInit() {

  }

}
