<div class="tagsinput">
  <ng-container *ngFor="let tag of selectedValues">
    <span class="tag ng-star-inserted">
      <span *ngIf="_labelField == ''" class="tag-text mr-2">{{ tag }}</span>
      <span *ngIf="_labelField !== ''" class="tag-text mr-2">{{ tag[_labelField] }}</span>
      <button class="tag-remove" (click)="removeTag(tag)"></button>
    </span>
  </ng-container>

  <div class="tag-add" #tagsAdd *ngIf="selectedValues?.length == 0 || multi">
    <input #typeaheadDirective="bs-typeahead"
        [(ngModel)]="asyncSearch"
       [typeahead]="dataSource"
       [adaptivePosition]="true"
       [typeaheadAsync]="true"
       [typeaheadOptionField]="_labelField"
       [typeaheadMinLength]="typeaheadMinLength"
       [typeaheadScrollable]="typeaheadScrollable"
       [typeaheadOptionsLimit]="typeaheadOptionsLimit"
       [typeaheadOptionsInScrollableView]="10"
       (typeaheadLoading)="onTypeaheadLoading($event)"
       (typeaheadOnSelect)="onSelect($event)"
       (typeaheadOnBlur)="callbackBlur($event)"
       [container]="containerBody ? 'body' : ''"
       [isAnimated]="isAnimated"
       [style]="'width:'+width"
       [placeholder]="placeholder"
       class="form-control {{ class }}"
       autocomplete="off" >
  </div>

</div>
<div *ngIf="typeaheadLoading && showLoading">Loading...</div>

