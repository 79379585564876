import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VendorPricingComponent } from './sections/vendor-pricing/vendor-pricing.component';
import { IdSuiteComponent } from './sections/id-suite/id-suite.component';

const routes: Routes = [
  {path: '',  component: PageNotFoundComponent},
  {path: 'projectvendor/:id/pricing', component: VendorPricingComponent},
  {path: 'idsuite', component: IdSuiteComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
