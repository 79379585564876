import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { QuotaQuestion } from 'core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuotaService {

baseUrl = environment.apiEndpoint + '/Quota';

constructor(private http: HttpClient) {}

  ListQuestions(): Observable<QuotaQuestion[]> {
    return this.http.get<QuotaQuestion[]>(this.baseUrl  + '/question');
  }

  GetQuestion(id: string): Observable<QuotaQuestion> {
    return this.http.get<QuotaQuestion>(this.baseUrl + '/question/' + id);
  }

  AddQuestion(model: any): Observable<QuotaQuestion> {
    return this.http.post<QuotaQuestion>(this.baseUrl + '/question', model);
  }

  EditQuestion(model: any): Observable<QuotaQuestion> {
    return this.http.put<QuotaQuestion>(this.baseUrl + '/question', model);
  }

  DeleteQuestion(model: any) {
    return this.http.delete(this.baseUrl + '/question/' + model.id);
  }

}
