import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'core';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'or-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() public set items(data) {
    this._items = data;
    if(this.layout != null) {
      this._items.forEach( (row: any) => {
        this.layout.forEach((col: any) => {
          if (col.editOnly) {
            row.editMode = row.editMode ?? {};
            row.editMode[col.id] = { enabled: true, value: row[col.id]};
          }
        });
      });
    }
    if (this.listsettings.sortBy != null) {
      this.sortList(this.listsettings.sortBy);
    }
    this.itemsToLoad = this.loadcount;
    this.updateDisplayList();
  }

  @Input() displayList: any;
  @Input() loadcount = 100;
  @Input() summary: any;
  @Input() showActionColLabel= false;
  @Input() layout: [];
  @Input() rowid = '';
  @Input() enableExport = true;
  @Input() clickroute = '';
  @Input() enableSelectedRowClass = false;
  @Input() exportFilename = 'export.csv';
  @Input() listsettings: any = { appliedFilters: [], sortBy: null };
  @Input() actions: [];
  @Output() selected = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() settingsupdate = new EventEmitter();

  _items: any;
  displaylist: any;
  sortBy = '';
  sortDir = '';
  appliedFilters = [];
  itemsToLoad = 0;
  itemCount = 0;

  constructor(
    private userService: UserService,
    private utilService: UtilsService,
    public auth: AuthService) { }

  ngOnInit() {
    if (this.listsettings.sortBy != null) {
      this.sortList(this.listsettings.sortBy, this.listsettings.sortDir, true);
    }
    else this.updateDisplayList();
  }

  updateDisplayList() {

    if (this._items ===  undefined || this._items === null) return;
    const items = this._items.filter(row => this.shouldShowRow(row))
    this.itemCount = items.length;
    this.displayList = items.splice(0, this.itemsToLoad);
  }
  loadMore() {
    this.itemsToLoad += this.loadcount;
    this.updateDisplayList();
  }
  // this function is decalred differently as it is passed to the child component and called from there
  getUserList = (filter) => {
    return this.userService.GetUsers();
  }
  sortList(col: any, order:string = 'ASC', force:boolean = false) {
    // Local Sort: for server side sort we will need to emit event so that calling component can fetch new data
    const numberToShow = this._items.length;

    if (this.listsettings.sortBy !== null && this.listsettings.sortBy.id === col.id && !force) {
      this.listsettings.sortDir = (this.listsettings.sortDir === 'ASC') ? 'DESC' : 'ASC';
      this._items = this._items.reverse();
    } else {
      this.listsettings.sortBy = col;
      this.listsettings.sortDir = order;

      let sortFn = (a, b) => (a[col.id] || '').localeCompare(b[col.id] || '');
      if (col.type === 'number' || col.type === 'alert'  || col.type === 'currency'  || col.type === 'percent') {
        sortFn = (a, b) => Number(a[col.id]) - Number(b[col.id]);
      } else if (col.type === 'users') {
        sortFn = (a, b) => (a[col.id] == null || a[col.id].length == 0 ? '' : a[col.id][0].name ?? '')
          .localeCompare(b[col.id] == null  || b[col.id].length == 0  ? '' : b[col.id][0].name ?? '');
      }

      this._items = this._items.sort(sortFn);
      if(order == 'DESC')
        this._items = this._items.reverse();
    }
    this.updateDisplayList();
    this.settingsUpdated();
  }

  settingsUpdated() {
    this.settingsupdate.emit(this.listsettings);
  }

  getFilterValues(col) {
    // TO DO : GO TO SERVER IF INFO NOT AVAILABLE IN LOCAL VALUES (SIMILAR FOR SORT)
    let values = {};
    if (col.type === 'users') {
      this._items.forEach( x => {
        if(x[col.id]?.length > 0) {
          for(var i=0; i< x[col.id]?.length; i++) {
            values[x[col.id][i].name] = (typeof values[x[col.id][i].name] === 'undefined') ? 1 : values[x[col.id][i].name] + 1;
          }
        }
      });
    } else if (col.type === 'tags') {
      this._items.forEach( x => {
        x[col.id].forEach(tag => {
          values[tag.tag] = (typeof values[tag.tag] === 'undefined') ? 1 : values[tag.tag] + 1;
        });
      });
    } else {
      values = this._items.map(x => x[col.id]).reduce((acc, curr) => {
        acc[curr] = (typeof acc[curr] === 'undefined') ? 1 : acc[curr] + 1;
        return acc;
      }, {});
    }

    const filterValues = [];
    const filterDetails = this.listsettings.appliedFilters.filter(e => e.id === col.id );

    for (const val in values) {
      let isSelected = (filterDetails.length > 0 && filterDetails[0].values.includes(val));
      filterValues.push({label: val, count: values[val], selected: isSelected});
    }
    col.filterValues = filterValues.sort((a, b) => (a.label > b.label) ? 1 : -1);

    this.updateDisplayList();
  }

  applyFilter(values, col) {
    const curFilters = this.listsettings.appliedFilters.filter(e => e.id !== col.id );
    if (values.length > 0) {
      if (col.type === 'bool') {
        curFilters.push({ id: col.id, label: col.label, values: values.map(e => e.label === 'true' )});
      } else {
        curFilters.push({ id: col.id, type: col.type, label: col.label, values: values.map(e => e.label)});
      }
    }
    this.listsettings.appliedFilters = curFilters;
    this.settingsUpdated();
    this.updateDisplayList();
  }

  removeFilter(col) {
    this.listsettings.appliedFilters = this.listsettings.appliedFilters.filter(e => e.id !== col.id );
    this.settingsUpdated();
    this.updateDisplayList();
  }
  exportTable() {
    let csvContent = '';

    // Colum headers
    this.layout.forEach((col: any) => {
      if (col.type === 'img' || col.type === 'actions-inline' || col.type === 'actions-dropdown'  || col.type === 'actions-checkbox') { return true; }

      csvContent += col.label + ',';
    });
    csvContent += '\n';

    // Data
    const items = this._items.filter( row => this.shouldShowRow(row));
    items.forEach( (row: any) => {
      this.layout.forEach((col: any) => {
        if (col.type === 'img' || col.type === 'actions-inline' || col.type === 'actions-dropdown' || col.type === 'actions-checkbox') { return true; }

        if (col.type === 'tag') {
          csvContent += row[col.id].label + ',';
        } else if(col.type === 'address') {
          csvContent += (row[col.id].address1 ?? '') + ',';
        } else if(col.type === 'text') {
          csvContent += '"' + ((row[col.id] ?? '' ) + '",');
        } else if(col.type === 'users') {
          csvContent += (row[col.id][0]?.name ?? '' ) + ',';
        } else if(col.type === 'cleanID') {
          csvContent += '"' + ((this.cleanIDImplementation(row, col) ?? '' ) + '",');
        } else {
          csvContent += (row[col.id] ?? '' ) + ',';
        }
      });
      csvContent += '\n';
    });

    this.utilService.generateXLSX(csvContent, this.exportFilename);
  }

  getCountdownString(inDate) {
    if (inDate == null) { return ''; }
    const msPerDay = 1000 * 3600 * 24; // transform milliseconds to total of days (milliseconds * seconds * hours)
    const theDate = new Date(inDate).valueOf();
    const totalDaysLeft = Math.ceil(( theDate - new Date().valueOf()) / msPerDay);
    return totalDaysLeft < 0 ? 'No Days Left' : totalDaysLeft === 1 ? '1 Day Left' : totalDaysLeft + ' Days Left';
  }

  shouldShowRow(row) {
    // MIGHT BE BETTER WAY TO DO THIS AND USE A FILTERED LIST WHICH UPDATES THE DATA
    if (this.listsettings != null && this.listsettings.appliedFilters && this.listsettings.appliedFilters.length === 0) { return true; }

    let found = true;

    for (let idx = 0; idx < this.listsettings.appliedFilters.length && found; idx++) {
      const filter = this.listsettings.appliedFilters[idx];
      if (filter.type === 'users') {
        if(row[filter.id] == null || row[filter.id].length == 0) { return false; }

        for(const user of row[filter.id]) {
          found = filter.values.includes(user.name);
          if(found)
            break;
        }
      } else if (filter.type === 'tags') {
        const matches = row[filter.id].find(tag => filter.values.includes(tag.tag));
        found = (matches != null);
      } else {
        found = filter?.values?.includes(row[filter.id]);
      }
    }
    return found;
  }

  rowSelected(col, row) {
    if (col.selectable) {

      //add class "table-info" to highlight the selected row
      if (this.enableSelectedRowClass)
        row.selected = true;

      this.selected.emit(row);
    }
  }

  enableEdit(item, col) {
    let val =  item[col.id];

    if (item.editMode == null) { item.editMode = {}; }

    if (col.type === 'date' || col.type === 'countdown') {
      val = (item[col.id] === null) ? new Date() : new Date(item[col.id]);
    }

    item.editMode[col.id] = { enabled: true, value: val};
  }

  autoSaveChange(newVal, item, col) {
    item.editMode[col.id].value = newVal;
    if (col.autoSave) {
        if (col.type === 'date'  || col.type === 'countdown') {
          if (new Date(item[col.id]).getTime() !== newVal.getTime()) {
            this.saveChange(item, col);
          }
        } else if (item[col.id] !== newVal) {
          this.saveChange(item, col);
        }
     }
  }

  saveChange(item, col) {
    const newValue = item.editMode[col.id].value;
    item[col.id] = newValue; // update locally while we wait to save to DB, TO DO: MAYBE SHOW SMALL LOADER
    if(col.editOnly !== true) {
      item.editMode[col.id] = null;
    }
    this.changed.emit({ item, col, newValue});
  }

  actionSelected(row, type, evt = null) {
    this.action.emit({row, type, evt});
  }

  onWindowScroll(evt: Event) {
    // const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // const tableOffsetTop = evt.target.offset().top;
  }

  isArrayOfObjects(value: any): boolean {
    return Array.isArray(value) && typeof value[0] === 'object';
  }

  cleanIDImplementation(item, col): string {
    if (item.cleanIDEnable == false) {
      return "Disabled"
    } else if (item.cleanIDEnable == true && item.cleanIDImplementation == null) {
      return "Enabled"
    }
    return item[col.id]
  }
}
