<div class="upload h-100" *ngIf="mode == 'full'" [class.d-flex]="showPreviewImg">
  <div *ngIf="showPreviewImg" class="col-3 p-0 mr-3">
    <img #imagePreview [src]="this.fileUrl||'https://navigator-images.s3.us-east-2.amazonaws.com/no-image.svg'" class="img-thumbnail" alt="..." />
  </div>
  <div class="h-100 w-100">
    <div class="upload-container h-100 w-100">
      <div class="border-container py-4 px-5 h-100 d-flex align-items-center justify-content-center">
        <input #fileInput type="file" multiple="false" id="fileUploadNav" name="fileUploadNav" accept="{{acceptTypes}}" (change)="fileAdded($event)" />
        <div>
          <div *ngIf="!showFileIcon"><i class="fas fa-solid fa-2x fa-file-arrow-up grey4 mb-3"></i></div>
          <span class="d-block nav-accent-blue font-weight-bold nav-font16 main-message">{{ mainMsg }}</span>
          <span class="d-block grey2 nav-font14 small-message">{{ smallMsg }}</span>
          <a [href]="downloadLink" class="nav-font12 nav-accent-blue position-relative download-link" *ngIf="downloadLink">Download Template</a>
          <div *ngIf="file != null && showFileName == true">
            <h6 class="grey4 mt-2">{{ file.name }} </h6>
          </div>
          <div *ngIf="includeProgressBar && this.uploadProgress > 0 && this.uploading" class="progress mt-1">
            <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" [style.width]="this.uploadProgress + '%'"
            [attr.aria-valuenow]="this.uploadProgress" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="upload h-100 w-100" *ngIf="mode !== 'full' && mode !== 'qc-flow'">
  <div class="row h-100 w-100">
    <div class="upload-container bg-transparent h-100">
      <div class="text-left">
        <input #fileInput type="file" multiple="false" id="fileUploadNav" name="fileUploadNav" accept="{{acceptTypes}}" (change)="fileAdded($event)" />
        <div [class]="mode == 'button' ? 'btn btn-light border shadow-sm' : ''">
          <a (click)="fileInput.click()" id="fileBrowser" class="or-title d-inline-block cursor-pointer">
            <small class="font-weight-bold">{{compactModeText}}</small>
          </a>
          <div *ngIf="file != null && showFileName == true">
            <small>{{ file.name }}</small>
          </div>
          <div *ngIf="includeProgressBar && this.uploadProgress > 0 && this.uploading" class="progress mt-1">
            <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" [style.width]="this.uploadProgress + '%'"
            [attr.aria-valuenow]="this.uploadProgress" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="upload h-100 w-100" *ngIf="mode === 'qc-flow'">
  <div class="h-100 w-100">
    <div class="upload-container h-100 w-100">
      <div class="border-container py-4 px-5 h-100 d-flex align-items-center justify-content-center">
        <input #fileInput type="file" multiple="false" *ngIf="!file" id="fileUpload" name="fileUpload" accept="{{acceptTypes}}" (change)="fileAdded($event)" />
        <div *ngIf="file == null">
          <span class="d-block nav-accent-blue font-weight-normal nav-font14"><i class="nav-font18 fak fa-upload grey4 mx-2"></i><span class="mainMsgRIDWOrkflow nav-font16">{{ mainMsg }}</span></span>
          <span class="d-block grey4 nav-font14">{{ smallMsg }}</span>
          <a [href]="downloadLink" class="nav-font12 nav-accent-blue position-relative download-link" *ngIf="downloadLink">Download Template</a>
          <div *ngIf="includeProgressBar && this.uploadProgress > 0 && this.uploading" class="progress mt-1">
            <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" [style.width]="this.uploadProgress + '%'"
            [attr.aria-valuenow]="this.uploadProgress" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div *ngIf="file != null && showFileName == true" class="d-flex align-items-center">
          <i class="fa-solid fa-file-csv" style="color: #107c41;"></i>
          <h6 class="grey4 mt-2 d-inline ml-2 mr-2">{{ file.name }} </h6>
          <span class="grey3 nav-font12 mr-3">{{fileSize}} Mb</span>
          <i class="fak fa-trash fa-sm cursor-pointer nav-error" (click)="deleteFile()" tooltip="Delete file" placement="right"></i>
        </div>
      </div>
    </div>
  </div>
</div>

