<div *ngIf="showFeedbackRequired" class="invalid-feedback {{ showFeedbackRequired ? 'd-block' : '' }}">{{ invalidFeedbackRequired }}</div>
<div *ngIf="showFeedbackMinlenght" class="invalid-feedback {{ showFeedbackMinlenght ? 'd-block' : '' }}">{{ invalidFeedbackMinlenght }}</div>
<div *ngIf="showFeedbackEmail" class="invalid-feedback {{ showFeedbackEmail ? 'd-block' : '' }}">{{ invalidFeedbackEmail }}</div>

<!-- HOW TO USE -->
<!--
<or-error-display
    [showFeedbackRequired]="submitted && utils.hasErrorRequired(registerForm, 'password')"
    [showFeedbackMinlenght]="submitted && utils.hasErrorMinlenght(registerForm, 'password')"
    invalidFeedbackRequired="Password is required!"
    invalidFeedbackMinlenght="Password must be at least 4 characters!">
  </or-error-display>
-->
