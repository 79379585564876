import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-id-suite',
  templateUrl: './id-suite.component.html',
  styleUrls: ['./id-suite.component.scss']
})
export class IdSuiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
