<div class="container">
  <div class="row">
    <img alt="OpinionRoute" class="img-thumbnail border-0 mx-auto d-block" width="350" src="https://nav2.navigatorsurveys.com/assets/imgs/logo.png" />
  </div>
  <hr>
  <div class="row d-flex align-items-center justify-content-center">
    <img src="../../assets/sad.png" class="img-thumbnail border-0 mr-2" width="50" />
    <h4 class="opacity-70">Oops, Page Not Found!</h4>
  </div>
</div>
