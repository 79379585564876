import { Injectable } from '@angular/core';
import { Instance, SiteSection } from 'core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {
  baseUrl = environment.apiEndpoint + '/instance/';

  constructor(private http: HttpClient) { }

  getInstances(): Observable<Instance[]> {
    return this.http.get<Instance[]>(this.baseUrl + '');
  }

  getInstance(id: any): Observable<Instance> {
    return this.http.get<Instance>(this.baseUrl + id);
  }

  addInstance(model: any): Observable<Instance> {
    return this.http.post<Instance>(this.baseUrl + '', model);
  }

  editInstance(model: any): Observable<Instance> {
    return this.http.put<Instance>(this.baseUrl + model.id, model);
  }

  deleteInstance(id: string) {
    return this.http.delete(this.baseUrl + id);
  }

  getUserSections(): Observable<SiteSection[]> {
    return this.http.get<SiteSection[]>(this.baseUrl + 'setup/userSections', { headers : {cache : '0'}});
  }

  getSections(instanceId): Observable<SiteSection[]> {
    return this.http.get<SiteSection[]>(this.baseUrl + 'setup/sections?instanceId='+ instanceId, { headers : {cache : '0'}});
  }

  addSection(model: any): Observable<SiteSection> {
    return this.http.post<SiteSection>(this.baseUrl + 'setup/sections', model);
  }

  editSection(model: any): Observable<SiteSection> {
    return this.http.put<SiteSection>(this.baseUrl + 'setup/sections/' + model.id, model);
  }

  deleteSection(id: any): Observable<SiteSection> {
    return this.http.delete<SiteSection>(this.baseUrl + 'setup/sections/' + id);
  }

  UpdateInstanceValue(instanceId: string, fieldId: string, value: any): Observable<any> {
    let field: any = {fieldId};
    if (typeof value === 'boolean') {
      field.bVal = value;
    }
    else if (typeof value === 'string') {
      field.sVal = value;
    }
    else if (typeof value === 'number') {
      field.nVal = value;
    }
    else if (value instanceof Date) {
      field.dVal = value;
    }
    return this.http.put<any>(this.baseUrl + instanceId + '/field', field );
  }

}
