<div>
  <div *ngIf="_headers?.length > 0">
    <!-- Header Row -->
    <div *ngIf="!_showCount">
      <div class="row">
        <div *ngFor="let header of _headers" [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
          <span>{{header}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="_showCount">
      <div class="row" *ngIf="_headers?.length === 2">
        <div class="col-6">
          <span>{{_headers[0]}} ({{_unselectedFields.length}})</span>
        </div>
        <div class="col-6">
          <span>{{_headers[1]}} ({{_selectedFields.length}})</span>
        </div>
      </div>
      <div class="row" *ngIf="_headers?.length === 3">
        <div class="col-4">
          <span>{{_headers[0]}} ({{_distinctParents.length}})</span>
        </div>
        <div class="col-4">
          <span>{{_headers[1]}} ({{_unselectedFields.length}})</span>
        </div>
        <div class="col-4">
          <span>{{_headers[2]}} ({{_selectedFields.length}})</span>
        </div>
      </div>
    </div>
    <hr/>
  </div>
  <!-- Listbox Row -->
  <div class="row">
    <div *ngIf="_headers?.length > 2" class="col-4">
      <div *ngFor="let parent of _distinctParents" (click)="handleParent(parent)">
        <i [attr.class]="_selectedParents.includes(parent) ? 'far fa-check-square' : 'far fa-square'"></i><span> {{parent}}</span>
      </div>
    </div>
    <div [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
      <div *ngFor="let field of _unselectedFields" (click)="addRemoveField(field.id, 'add')">
        <i class="fa fa-plus"></i><span> {{field.label}}</span>
      </div>
    </div>
    <div [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
      <div *ngFor="let field of _selectedFields" (click)="addRemoveField(field.id, 'remove')">
        <i class="fa fa-minus"></i><span> {{field.label}}</span>
      </div>
    </div>
  </div>
</div>
