
<div dropdown #dropdown="bs-dropdown" [container]="container">
  <ul id="dropdown-list" *dropdownMenu class="dropdown-menu dropdownListMenu dropdown-menu-right py-0" 
      [ngClass]="(showList || !showWhenClosed) ? 'shadowBorder dropdown-open' : 'noShadow dropdown-closed'" 
      role="menu" aria-labelledby="button-basic">
    <li role="menuitem"class="expandable dropdown-list" (click)="showWhenClosed ? toggleDropdown() : null">
      <a *ngIf="!search" class="dropdown-list-item cursor-pointer text-truncate"
      [ngClass]="current === null ? 'selectionPlaceholder' : ''">
      {{current != null ? current.name : noSelectionText}}
          <i class="far fa-lg grey4 float-right mt-1 pt-2"
            [class.fa-angle-up]="showWhenClosed && showList"
            [class.fa-angle-down]="showWhenClosed && !showList">

          </i>
      </a>
      <a *ngIf="search" class="dropdown-list-item cursor-pointer text-truncate px-2">
        <input #search class="editable inputSearch py-1 px-1 " spellcheck="false" placeholder="{{noSearchText}}" (keyup.enter)="values.length > 0 && current == null ? applyValue(null, values[0]) : null" (ngModelChange)="getValues($event);" [ngModel]="searchFilter">
      </a>
      <hr *ngIf="(showWhenClosed && showList) || !showWhenClosed" class="p-0 m-0 currentSeperator">
    </li>
    <li *ngIf="current != null && !showWhenClosed">
      <a class="dropdown-list-item text-truncate current grey2 cursor-pointer">{{ current.name | slice:0:20}}
        <span *ngIf="current.name.length>20">...</span>
        <i class="float-right fas fa-check grey2 mt-1"></i>
      </a>
      <hr class="p-0 my-0 mx-1">

    </li>
    <ng-container *ngIf="(showWhenClosed && showList) || !showWhenClosed">
      <li role="menuitem" *ngFor="let value of values" class="expandable">
        <a class="dropdown-list-item cursor-pointer text-truncate  " (click)="applyValue($event, value)">{{ value.name }}</a>
        <hr class="p-0 my-0 mx-1">
      </li>
    </ng-container>
    <ng-container *ngIf="createEnabled && ((showWhenClosed && showList) || !showWhenClosed)">
      <li role="menuitem" class="expandable mx-1 px-1 border-radius10" [ngClass]="createMode ? 'createValueContainerActive': 'createValueContainer'">
        <a class="dropdown-list-item dropdown-create-btn cursor-pointer font-weight-bold d-flex align-items-center px-1" (click)="createMode=true">
          <i class="far fa-plus"></i>&nbsp;&nbsp; {{createText}}
          <i [ngStyle]="{'visibility': createMode ? 'visible' : 'hidden'}" class="far fa-times grey4 ml-auto" (click)="$event.preventDefault(); $event.stopPropagation();endCreate()"></i>
        </a>
        <a *ngIf="createMode" class="">
          <input #createInput class="dropdown-input form-control-nav form-control-sm mb-2 w-100 grey5 nav-font-14" spellcheck="false" placeholder="Type value and press enter" (keyup.enter)="createNewValue($event)" [(ngModel)]="newValue">
        </a>
      </li>

    </ng-container>
  </ul>
</div>



