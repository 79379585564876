
<div class="row mt-4 ml-3 mb-4">
    <div class="row d-flex" style="padding-top: 180px; width: 90px;">
      <div *ngIf="data.length != 0" class=" --or-secondary" >
        <div class="yLabel">{{y_label}}</div>
      </div>

    </div>

    <div class="col-10">
      <div *ngIf="data.length != 0" class="--or-secondary xLabel">{{x_label}}</div> 
      <div [ngClass]="{'table-responsive' : data.length > 19}" class="tableDiv">
        <table class="table mt-3 table-sm table-bordered">
          <thead>
            <tr class="mainRow">
              <td *ngIf="data.length != 0" class="blankCell"></td>
              <ng-container *ngFor="let v of data; let i = index">
                <td style=" text-align:center;" [ngClass]="{'first' : i == 0}">
                  <button class="btn btn-secondary removeBtn" (click)="removeElement(i)"><i class="fa fa-times fa-sm"></i></button>
                  <hr>
                  <div class="input-group input-group-sm">
                    <input 
                      (keypress)="numberOnly('percent', v.actual, $event)" type="text"
                      maxlength="3"
                      (ngModelChange)="valueChange(i, $event)" 
                      (change)="sort()" 
                      [(ngModel)]=v.actual 
                      class="form-control form-control-xs">
                    <div class="input-group-append" *ngIf="this.type == 'percent'">
                      <span class="input-group-text">{{sign}}</span>
                    </div>
                  </div>
                </td>

              </ng-container>
              
            </tr>
          </thead>
          <tbody>
              <ng-container>
                <tr *ngFor="let v of data; let i_x = index">
                  <th class="readonlyPercent fix">
                    <div class="input-group input-group-sm">
                      <input readonly type="" [(ngModel)]=v.actual class="form-control form-control-xs">
                      <div class="input-group-append" *ngIf="this.type == 'percent'">
                        <span class="input-group-text">{{sign}}</span>
                      </div>
                    </div>
                  </th>
                  <ng-container *ngFor="let s of v.links; let i_y = index">
                    <td tooltip="{{s.expected || 'null' }}, {{v.actual || 'null' }}"
                      >
                      <input 
                        maxlength="3"
                        (keypress)="numberOnly('penalty', s.value, $event)" type="text"
                        [(ngModel)]=s.value
                        (ngModelChange)="scoreChange(i_x, i_y, $event)"
                        [ngStyle]="
                        (([0, 1, 2, 3].includes(s.value) && {'background-color': 'rgb(0,128,0, 0.3)'} ||
                          [4, 5, 6].includes(s.value) && {'background-color': 'rgb(255,140,0,0.3)'} ||
                          [7, 8, 9, 10].includes(s.value) && {'background-color': 'rgb(255,0,0,0.3)'}) ||
                          ((i_y == i_x) && (!s.value)) && {'background-color': 'whitesmoke'})" 
                         
                        class="form-control form-control-sm">

                    </td>
                  </ng-container>
                </tr>
              </ng-container>
          </tbody>
        </table>

      </div>
      
      <div class="row mt-2">
        <div class="col-2"></div>
        <div class="col-1 p-0">
          <div class="input-group input-group-sm">
            <input 
            (keypress)="numberOnly('percent', inputVal, $event)" type="text"
            maxlength="3" 
            [(ngModel)]="inputVal" class="form-control form-control-xs">
            <div class="input-group-append" *ngIf="sign == '%'">
              <span class="input-group-text">{{sign}}</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <button 
            class="btn btn-sm btn-secondary inline" 
            type="button" 
            
            (click)="addCol()"><em class="fa fa-plus fa-sm"></em> add</button>
        </div>
      </div>
    </div>
  </div>