import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../environments/environment';
import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  baseUrl = environment.apiEndpoint + '/aws/';

  baseUrlFileUpload = environment.apiEndpoint + '/project/';
  file: File;
  uploading = false;
  uploadComplete = false;
  uploadProgress = 0;
  url = '';

  constructor(private http: HttpClient) {}

  upload(fileChangeEvent: any) {
    this.file = fileChangeEvent.target.files[0];
    this.uploading = true;
  }

  generatePresignedUrl(fileExtension: string, folder: string, publicBucket = false, fileName: string = '', instanceId: string = '', isCsv = false) {
    const url = this.baseUrl + 'uploadurl/' + ( publicBucket === true ? 'public' : 'private') + '?folder=' + folder + '&fileExtension=' + fileExtension + '&fileName=' + fileName+ '&instanceId=' + instanceId + '&isCsv=' + isCsv;
    return this.http.get<any>(url);
  }

  generatePresignedDownloadUrl(fileExtension: string, folder: string, publicBucket = false, fileId: string = '', instanceId: string = '') {
    var path = instanceId + "/" + folder + "/" + fileId + fileExtension;
    const url = this.baseUrl + 'downloadurl/' + ( publicBucket === true ? 'public' : 'private') + '?path=' + path;
    return this.http.get<any>(url);
  }

  uploadFileToS3(file: File, presignedUrl: string) {
    return this.http.put(presignedUrl, file, {
      reportProgress: true,
      observe: 'events'
      // tslint:disable-next-line: no-shadowed-variable
    });
  }

  addFile(projectId, fileUpload) {
    return this.http.post(this.baseUrlFileUpload + projectId + '/files', fileUpload);
  }
  addAsset(projectId, assetUpload) {
    return this.http.post(this.baseUrlFileUpload + projectId + '/assets', assetUpload);
  }

  getDownloadUrl(id, fileName = '') {
    return this.http.get(this.baseUrlFileUpload + 'files/' + id + '?filename='+fileName, {responseType: 'text'});
  }

  deleteFile(id) {
    return this.http.delete(this.baseUrlFileUpload + 'files/' + id);
  }
  deleteAsset(id) {
    return this.http.delete(this.baseUrlFileUpload + 'assets/' + id);
  }

  downloadListOfFiles(projectId: string) {
    return this.http.get<any[]>(this.baseUrlFileUpload + projectId + '/files');
  }
  getListOfAssets(projectId: string) {
    return this.http.get<any[]>(this.baseUrlFileUpload + projectId + '/assets');
  }
}
