<ng-container *ngIf="names?.length > 0 || name || url">

  <ng-container *ngIf="names?.length == 0">
    <div [ngClass]="'image-container ' + type + ' ' + size" [class.selected]="selected">
      <img *ngIf="url != null &&  url != ''" [src]="url" [alt]="name" />
      <ng-container *ngIf="url == null || url == ''">
        <div *ngIf="showInitials" class="initials">
          <span class="editable" *ngIf="this.name">{{ getInitials(name) }}</span>
        </div>
        <div *ngIf="!showInitials" class="picture"></div>
      </ng-container>
      <span *ngIf="icon != null && icon != ''" class="badge badge-info notify-badge"><i [ngClass]="'fa ' + icon" aria-hidden="true"></i></span>
    </div>
  </ng-container>

  <!-- multiple types (PM, SecondPM, AccountOwner) -->
  <ng-container *ngIf="names?.length > 0">
    <div class="row">
      <ng-container *ngFor="let item of names; let idx = index">
        <div [style.z-index]="names.length-idx"
            [ngClass]="'image-container multiple ' + type + ' ' + size + ' ' + item?.type"
            [class.selected]="selected"
            [tooltip]="getTooltip(item)">
          <img *ngIf="url != null &&  url != ''" [src]="url" />
          <ng-container *ngIf="url == null ||  url == ''">
            <div *ngIf="showInitials" class="initials">
              <span class="">{{ getInitials(item?.name) }}</span>
            </div>
            <div *ngIf="!showInitials" class="picture"></div>
          </ng-container>
          <span *ngIf="icon != null && icon != ''" class="badge badge-info notify-badge"><i [ngClass]="'fa ' + icon" aria-hidden="true"></i></span>
        </div>
      </ng-container>
    </div>
  </ng-container>

</ng-container>






