export interface Role {
    id?: string;
    name?: string;
    instanceId?: string;
    instanceRoleId?: string;
    role?: string;
    description?: string;
    type?: string;
    users?: RoleUser[];
}

export interface RoleUser {
  id?: string;
  instanceRoleId?: string;
  role?: string;
  userId?: string;
  userName?: string;
  email?: string;
  message?: string;
}

export interface SupportAccessRequest {
  userId?: string;
  requestSent?: Date;
  requestSentTo?: string;
  requestSentFrom?: string;
  accessExpiresTime?: Date;
  accessExpiresTimeZone?: string;
  status?: string;
  statusUpdatedTime?: Date;
  lastUpdatedTime?: Date;
  userNotifiedFlag?: boolean;
  isEnabled?: boolean;
}

