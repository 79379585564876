import { Injectable} from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ReconciliationService {

  baseUrl = environment.apiEndpoint + '/project';

  constructor(private http: HttpClient) {  }

  setReconciliaton(formData: FormData) {
    return this.http.post(this.baseUrl + '/reconciliation', formData);
  }

  GetReconciliationFiles(projectId: string) {
    return this.http.get<any>(this.baseUrl + '/' + projectId + '/reconciliation');
  }

  GetSummary(projectId: string) {
    return this.http.get<any>(this.baseUrl + '/' + projectId + '/reconciliation/summary');
  }

  UploadQC(projectId: string, fileId: string) {
    return this.http.get<any>(this.baseUrl + '/' + projectId + '/reconciliation/quickupdate/' + fileId );
  }

  RollbackAll(projectId: string) {
    return this.http.get<any>(this.baseUrl + '/' + projectId + '/reconciliation/rollback');
  }
  uploadLink(fileToUpload, projectId) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('projectId', projectId);
    return formData;
  }

  uploadLink1(files) {
    if (files === null) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return formData;
  }

}
